import { useEffect, useState } from "react";

import { FormGroup, Label, Input, FormText } from 'reactstrap';

import Card from "../../../components/Card/Card";
import InputList from "../../../components/InputList/InputList";
import BasePage from "../../BasePage/BasePage";

import { useConfigContext } from "../../../contexts/ConfigContext";
import { useUserContext } from "../../../contexts/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";

import axios from "axios";

const RequestProfilePage = ({ subscribeOnFinish }) => {
    const [names, setNames] = useState([]);
    const [lastnames, setLastnames] = useState([]);
    const [ institution, setInstitution ] = useState("");
    const [ code, setCode ] = useState("");

    const { startLoading, stopLoading } = useConfigContext();
    const { whoami, user } = useUserContext();
    const [ openSnack ] = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if(institution !== "UCA") {
            setCode("")
        }
    }, [institution]);

    useEffect(() => {
        if(user) {
            setCode(user.code ?? "");
            setInstitution(user.institution ?? "");
            setNames(user.names ? user.names.map(name => ({ id: `name_${Math.random()}`, value: name })) : []);
            setLastnames(user.lastnames ? user.lastnames.map(name => ({ id: `lastname_${Math.random()}`, value: name })) : []);
        }
    }, [user])

    const onAddNames = (value) => {
        setNames([...names, {value, id: `name_${new Date().getTime()}`}]);
    }

    const onAddLastnames = (value) => {
        setLastnames([...lastnames, {value, id: `lastname_${new Date().getTime()}`}]);
    }

    const onRemoveName = (value) => {
        setNames(names.filter(val => val.id !== value.id))
    }

    const onRemoveLastname = (value) => {
        setLastnames(lastnames.filter(val => val.id !== value.id))
    }

    const onSubmitProfile = async (rejected = false) => {
        startLoading("fill-profile");
        try {
            const body = rejected ? { wantTo: false } : 
                {
                    wantTo: true,
                    code: code ? code.toUpperCase() : undefined, 
                    institution: institution ? institution : undefined,
                    names: names.length > 0 ? names.map(name => name.value) : undefined,
                    lastnames: lastnames.length > 0 ? lastnames.map(lastname => lastname.value) : undefined
                }
            await axios.post("/user/profile", body);
            subscribeOnFinish  && subscribeOnFinish();
            whoami();
        } catch (error) {
            if(error.response) {
                switch (error.response.status) {
                    case 409:
                        openSnack(t("snack_error_user_already"));
                        break;
                    case 400:
                        openSnack(t("snack_error_wrong_fields"));
                        break;
                    default:
                        openSnack(t("snack_error_unexpected"));
                }
            } else {
                openSnack(t("snack_error_signup_fail"));
            }
        } finally {
            stopLoading("fill-profile");
        }
    }

    return (
        <BasePage className="md:!p-4" center>
            <Card className="flex flex-col gap-4 flex-1 md:flex-none bg-white">  
                <h1 className="text-center"> {t("profile_main_title")} </h1>
                
                <p className="text-center text-gray-600">
                {t("profile_summary_text")}
                </p>

                <form onSubmit={(e)=> {e.preventDefault()}} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputList
                        id="profile-names-input"
                        name="names"
                        values={names}
                        onAdd={onAddNames}
                        onRemove={onRemoveName} 
                        label={t("profile_names_label")}/>

                    <InputList
                        id="profile-lastnames-input"
                        name="lastnames"
                        values={lastnames}
                        onAdd={onAddLastnames}
                        onRemove={onRemoveLastname} 
                        label={t("profile_lastnames_label")}/>
                    
                    <FormGroup>
                        <Label for="profile-institution-input">
                            {t("profile_organization_label")}
                        </Label>
                        <Input
                            name="institution" 
                            value={institution}
                            onChange={({ target })=> { setInstitution(target.value); }}
                            id="profile-institution-input"/>
                    </FormGroup>

                    <FormGroup>
                        <Label id="code-label-icon"
                            className="flex gap-2 items-center" 
                            for="profile-code-input">
                            {t("profile_code_label")}
                        </Label>
                        <Input
                            disabled={ institution !== "UCA" }
                            name="code" 
                            value={code}
                            onChange={({ target })=> { setCode(target.value); }}
                            id="profile-code-input"/>
                        { institution !== "UCA" &&
                            <FormText>
                                {t("profile_organization_subtext")}
                            </FormText>
                        }
                    </FormGroup>

                    <button onClick={()=> onSubmitProfile(true)} 
                        className="bg-rose-900 hover:bg-rose-800 text-white p-2 rounded-md">
                        {t("profile_not_now_btn")}
                    </button>

                    <button onClick={()=> onSubmitProfile()} 
                        className="bg-green-900 hover:bg-green-800 text-white p-2 rounded-md">
                        {t("profile_continue_btn")}
                    </button>
                </form>
            </Card>
        </BasePage>
    );
}

export default RequestProfilePage;