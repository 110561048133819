/* eslint-disable react-hooks/exhaustive-deps */
import BasePage from "../../BasePage/BasePage";
import Slogan from "./Slogan/Slogan";
import Careers from "./Careers/Careers";
import Sponsors from "./Sponsors/Sponsors";

import Speakers from "./Speakers/Speakers";
import { getTranslation } from "../../../services/translation";

const IndexPage = ({ lang, date, eventInfo }) => {

  return (
    <BasePage className="!p-0 md:flex-col child:py-8 child:flex child:justify-center w-full relative items-center">
      <Slogan
        title={getTranslation(eventInfo, "slogan", lang)}
        description={getTranslation(eventInfo, "summary", lang)}
        dates={date}
        lang={lang}
      />
      {<Speakers />}
      {<Careers />}
      {<Sponsors />}
    </BasePage>
  );
};

export default IndexPage;
