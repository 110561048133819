/* eslint-disable react-hooks/exhaustive-deps */
import BasePage from "../BasePage/BasePage";
import Footer from "../../components/Footer/Footer";
import LoginCard from "../../components/LoginCard/LoginCard";
import { useUserContext } from "../../contexts/UserContext";

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useSnackbar} from "react-simple-snackbar";
import { useTranslation } from "react-i18next";

const AuthPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { login, register, onForgotPassword, onPasswordRecovery, token } = useUserContext();
    const [openSnack] = useSnackbar();

    const onSubmitSignIn = (...params) => {
        if(params.length < 1) return;
        login(...params);
    }
    
    const onSubmitSignUp = (...params) => {
        if(params.length < 1) return;
        if(params.length === 1) {
            login(...params);
        }

        register(params[0], params[1], ()=> {
            openSnack(t("snack_info_register_success"));
            navigate("/auth/signin");
        });
    }

    const onSubmitForgotPassword = (identifier) => {
        onForgotPassword(identifier, () => {
            openSnack(t("snack_info_mail_sent"));
            navigate("/auth/signin");
        });
    }
    
    const onSubmitPasswordRecovery = (token, password) => {
        onPasswordRecovery(token, password, () => {
            openSnack(t("snack_info_password_recovered"));
            navigate("/auth/signin");
        });
    }

    useEffect(() => {
        const to = state ?? "/app/";
        if(token) {
            navigate(to);
        }
    }, [token, navigate])

    return(
        <> 
            <BasePage className="md:!p-4" center>
                <LoginCard 
                    onSubmitSignIn={ onSubmitSignIn } 
                    onSubmitSignUp={ onSubmitSignUp } 
                    onSubmitForgotPassword={ onSubmitForgotPassword }
                    onSubmitPasswordRecovery={onSubmitPasswordRecovery}/>
            </BasePage>
            <Footer showLogos={false}/>
        </>
    );
}

export default AuthPage;