import { useState } from 'react';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FormGroup, Input, Label } from 'reactstrap';

import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = ({ onForgotPassword }) => {
  const [identifier, setIdentifier] = useState("");
  const { t } = useTranslation();

  const onSubmitForm = (e) => {
    e.preventDefault();
    onForgotPassword(identifier)
  }

  return (
    <>
      <form onSubmit={onSubmitForm} className='min-w-0 flex flex-col justify-center p-2 child:my-2'>
        <div className="flex items-center gap-2">
          <Link className="cursor-pointer text-2xl text-black" to="/auth/signin"> <IoArrowBackCircleSharp /> </Link>
          <h2 className="!mb-0 flex-1" > { t("auth_fp_title") } </h2>
        </div>

        <FormGroup>
          <Label for="forgot-password-identifier"> {t("auth_form_email")} </Label>
          <Input
            id="forgot-password-identifier"
            name="identifier"
            placeholder={t("auth_form_email_eg")}
            value={identifier}
            onChange={({ target }) => { setIdentifier(target.value) }}
            type="email" />
        </FormGroup>

        <button type="submit" className="p-2 bg-primary text-black rounded-md shadow-md">
          { t("auth_form_send_mail_btn") }
        </button>
      </form>
    </>
  );
}

export default ForgotPasswordForm;