import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import BasePage from "../../../BasePage/BasePage";

const ChooserPage = () => {
    const [presentations, setPresentations] = useState([]);

    const [openSnack] = useSnackbar();
    const { startLoading, stopLoading } = useConfigContext();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPresentations = async () => {
            startLoading("fetch-presentations-attend");
            try {
                const { data } = await axios.get("/presentation/conia2024");
                setPresentations(data.data);
            } catch (error) {
                openSnack("Ocurrió un error");
                setPresentations([]);
            } finally {
                stopLoading("fetch-presentations-attend");
            }
        }

        fetchPresentations();
     }, []);

     const onChoose = (presentation) => {
        navigate("/app/attend/reader", { state: presentation });
     }

    return (
        <BasePage center stretched>
            <div className="w-full max-w-4xl p-4 flex flex-col gap-1  text-zinc-100 ">
                <h3> Lista de ponencias </h3>
                <div className="bg-primary w-full h-[2px] my-2" />

                <div className="flex flex-col">
                    {
                        presentations.map(p => (
                            <div key={p._id} className="p-2">
                                <div className="w-full gap-3 flex items-center justify-center flex-wrap">
                                    <p className="font-bold">
                                        { new Date(p.datetime).toLocaleDateString("es", { hour: "2-digit", minute: "2-digit" }) }
                                    </p>
                                    <p className="w-32 text-center font-bold">
                                        { p.onCampusLocation.name }
                                    </p>
                                    <p className="flex-1 min-w-full md:min-w-[200px] text-justify">
                                        { p.name[0]?.value }
                                    </p>

                                    <button onClick={() => onChoose(p)}
                                        className="p-2 flex-1 min-w-[200px] md:max-w-[200px] bg-conia-dark text-conia-light rounded-md">
                                        Entrada
                                    </button>
                                </div>
                                <div className="h-[1px] mt-2 bg-conia-dark opacity-50" />
                            </div>
                        ))
                    }
                </div>
            </div>
        </BasePage>
    )    
}

export default ChooserPage;