import { useCallback, useEffect } from 'react';
import ROLES from './../../constants/ROLES';

import { MdBookmark, MdBookmarks, MdEvent } from "react-icons/md";
import { FaUserFriends, FaTicketAlt } from "react-icons/fa";

import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer';
import PrivatePage from '../../wrappers/PrivatePage/PrivatePage';

import { useNavigate, useLocation, Route, Routes, Navigate } from 'react-router-dom';

import LandingPage from './User/LandingPage/LandingPage';
import RequestProfilePage from './RequestProfilePage/RequestProfilePage';
import ChooserPage from './Attend/ChooserPage/ChooserPage';
import AttendReaderPage from './Attend/AttendReaderPage/AttendReaderPage';

import axios from 'axios';

import { useUserContext } from "../../contexts/UserContext";
import { useConfigContext } from '../../contexts/ConfigContext';
import { useInfoContext } from '../../contexts/InfoContext';
import { useSnackbar } from 'react-simple-snackbar/';
import { useTranslation } from 'react-i18next';
import MyPresentationPage from './User/MyPresentationsPage/MyPresentationsPage';
import CareerPage from '../MainRouterPage/CareerPage/CareerPage';
import { getTranslation } from '../../services/translation';

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const AppPage = () => {
    const { token, user, whoami } = useUserContext();
    const { startLoading, stopLoading, lang } = useConfigContext();
    const { careers } = useInfoContext();
    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const allPages = [
        {
            id: "my-schedule-app-page",
            title: t("header_my_schedule_text"),
            icon: <MdEvent />,
            url: "/my-schedule",
            role: ROLES.USER,
            disabled: false,
            component: <MyPresentationPage />,

        },
        {
            id: "careers-pages",
            title: t("header_careers_text"),
            icon: <MdBookmarks />,
            role: ROLES.USER,
            pages: careers.map(career => ({
                id: `${career.code}_page`,
                title: getTranslation(career, "name", lang),
                icon: <MdBookmark />,
                loginReq: false,
                role: ROLES.USER,
                disabled: false,
                component: <CareerPage />,
                url: `/career/${career.uri}`
            }))
        },
        {
            id: "attend-app-pages",
            title: "Asistencia",
            icon: <FaUserFriends/>,
            role: ROLES.ATTEND,
            pages: [
                {
                    id: "attend-list-app-page",
                    title: "Registrar Asistencia",
                    icon: <FaTicketAlt/>,
                    url: "/attend/list",
                    role: ROLES.ATTEND,
                    disabled: false,
                    component: <ChooserPage />,
                },
                {
                    id: "attend-reader-app-page",
                    title: "Lector",
                    icon: <FaTicketAlt/>,
                    url: "/attend/reader/",
                    hidden: true,
                    role: ROLES.ATTEND,
                    disabled: false,
                    component: <AttendReaderPage />,
                },
            ]
        },
    ];

    useEffect(() => {
        if (!token) {
            navigate("/auth/signin", {
                state: pathname
            });
        }
    }, [token, navigate, pathname]);

    const subscribe = useCallback((unsubs = false, onSuccess) => {
        const subscribeAsync = async () => {
            startLoading("subscribing-main");
            try {
                await axios.post(`/event/${unsubs ? "unsubscribe" : "subscribe"}`, { event: EVENT_ID });
                onSuccess();
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 409:
                            openSnack(unsubs ? t("snack_error_isnt_registered") : t("snack_error_already_registered"));
                            break;
                        case 400:
                            openSnack(t("snack_error_wrong_fields"));
                            break;
                        default:
                            openSnack(t("snack_error_unexpected"));
                            break;
                    }
                }
            } finally {
                stopLoading("subscribing-main");
            }
        }

        subscribeAsync();
    }, [openSnack, startLoading, stopLoading, t]);


    const pagesLinks = user ?
        allPages.filter(page => {
            const index = user.roles.findIndex(role => (role === page.role || role === ROLES.SYSADMIN));
            return index >= 0;
        }) : [];

    const flatRoutes = (pages = [], role = "") => {
        return pages.reduce((acc, page) => {
            return page.pages ? [...acc, ...flatRoutes(page.pages, page.role)] :
                [...acc, (
                    <Route key={page.id} path={page.url} element={
                        <PrivatePage role={page.role ?? role}>
                            {page.component}
                        </PrivatePage>
                    } />
                )];
        }, [])
    }

    const routesToRender = (
        <Routes>
            {flatRoutes(allPages)}

            <Route index element={user && user.role === ROLES.ADMIN ? (
                <Navigate to="/app/admin/events" />
            ) : (
                <PrivatePage>
                    <LandingPage onSubscribe={subscribe} />
                </PrivatePage>
            )} />
        </Routes>
    );

    const profileFillForm = (
        <RequestProfilePage subscribeOnFinish={() => { subscribe(false, whoami); }} />
    );

    const content = user ?
        user.requestProfile ? profileFillForm : routesToRender
        : <></>;

    return (
        <>
            <Header pages={pagesLinks} concatPath="/app" />
            {content}
            {/*<Footer showLogos={false} />*/}
        </>
    );
}

export default AppPage;