import Slider from "../../../../components/Slider/Slider";
import constants from "../../../../constants/API";

const sponsors = {
  platino: [
    {
      id: "pl_infrasal",
      name: "Grupo Infrasal",
      img: `${constants.FILE_BASEURL}/patrocinadores/Infrasal.png`,
      imageWidth: "w-96 h-64",
    },
  ],
  oro: [
    {
      id: "or_inquisalva",
      name: "Inquisalva",
      img: `${constants.FILE_BASEURL}/patrocinadores/Logo-INQUISALVA.png`,
      imageWidth: "w-64",
    },
    {
      id: "or_DISA",
      name: "DISA",
      img: `${constants.FILE_BASEURL}/patrocinadores/DISA.webp`,
      imageWidth: "w-48",
    },
    {
      id: "or_robles",
      name: "Grupo Robles",
      img: `${constants.FILE_BASEURL}/patrocinadores/Grupo_Robles.png`,
      imageWidth: "w-32",
    },
    {
      id: "or_Ingenyarse",
      name: "Ingenyarse",
      img: `${constants.FILE_BASEURL}/patrocinadores/Ingenyarse.webp`,
      imageWidth: "w-64",
    },
    {
      id: "or_fundaGondra",
      name: "fundaGondra",
      img: `${constants.FILE_BASEURL}/patrocinadores/fundaGondra.png`,
      imageWidth: "w-64",
    },
  ],
  plata: [
    {
      id: "plata_Telecom",
      name: "Telecom",
      img: `${constants.FILE_BASEURL}/patrocinadores/Telecom.png`,
      imageWidth: "w-48",
    },
    {
      id: "plata_Corinca",
      name: "Corinca",
      img: `${constants.FILE_BASEURL}/patrocinadores/Corinca.png`,
      imageWidth: "w-80",
    },
  ],
};
const Sponsors = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 overflow-hidden">
      <h1 className="text-3xl font-bold text-center">Patrocinadores</h1>
      {Object.keys(sponsors).map((key) => (
        <div
          key={key}
          className="flex flex-col gap-4 w-full justify-center items-center"
        >
          <h2 className="text-2xl font-bold text-center">
            {key.toUpperCase()}
          </h2>
          <div className="flex gap-8 justify-center items-center flex-wrap">
            {sponsors[key].map((sponsor) => (
              <img
                key={sponsor.id}
                src={sponsor.img}
                alt={sponsor.name}
                className={sponsor.imageWidth}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sponsors;
