import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

const EjesTematicos = ({
  page,
  isDesplegable,
  buttonRef,
  setIsDesplegable,
  menuRef,
  optionsGeneration,
  hoverEffect = "",
}) => {
  return (
    <div
      key={`nav-${page.id}`}
      className="relative group flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center text-text-md"
    >
      <button
        ref={buttonRef}
        className={`text-on-nav-color flex items-center justify-start lg:justify-center text-xl gap-2 ${hoverEffect}`}
        onClick={() => {
          setIsDesplegable(!isDesplegable);
        }}
      >
        <div className="text-accent-color-secondary">{page.icon}</div>
        <div className="flex items-center gap-0">
          {page.title}
          {!isDesplegable ? (
            <MdArrowDropDown className="text-2xl" />
          ) : (
            <MdArrowDropUp className="text-2xl" />
          )}
        </div>
      </button>

      {/* Menú desplegable */}
      <ul
        ref={menuRef}
        className={`lg:absolute ${
          isDesplegable ? "flex" : "hidden"
        } flex-col justify-start items-start pl-4 m-0 gap-2 pt-2 bg-nav-color lg:gap-4 lg:pl-8 lg:pr-8 lg:pt-4 lg:pb-4 lg:top-full lg:left-0 lg:w-80`}
      >
        {page.pages.map((subPage) => {
          return optionsGeneration(subPage);
        })}
      </ul>
    </div>
  );
};

export default EjesTematicos;
