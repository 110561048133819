import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../services/translation";
import { useConfigContext } from "../../../../contexts/ConfigContext";

import { MdCalendarToday, MdWatchLater } from "react-icons/md";
import { BiWorld } from "react-icons/bi";

const PresentationTable = ({ presentations = [], myPresentations = [], areAllMine = false, onSubscribe = () => { } }) => {
  const { t } = useTranslation();
  const { lang } = useConfigContext();

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm lg:text-base">
        <thead>
          <tr className="font-bold border-b-[1px] border-solid border-conia-dark text-center child:p-2">
            <th className="w-[100px]"> {t("career_datetime_table")} </th>
            <th className="min-w-[250px]"> {t("career_subject_table")} </th>
            {/* <th className="w-[25%] min-w-[150px]"> {t("career_register_table")} </th> */}
          </tr>
        </thead>
        <tbody>
          {
            presentations.map(presentation => (
              <tr key={presentation._id} className="even:bg-primary-100 child:p-2 child:align-middle">
                <td className="child:m-0">
                  <p className="flex items-center justify-start gap-2">
                    <MdCalendarToday className="text-secondary" /> {new Date(presentation.datetime).toLocaleDateString()}
                  </p>
                  <p className="flex items-center justify-start gap-2">
                    <MdWatchLater className="text-secondary" /> {new Date(presentation.datetime).toLocaleTimeString(undefined, { hour12: false }).replace(/:..$/, "")}
                  </p>
                  <p className="flex items-center justify-start gap-2">
                    <BiWorld className="text-secondary" /> {new Date(presentation.datetime).toTimeString().replace(/\(.+\)/, "").substring(8)}
                  </p>
                </td>
                <td>
                  <div className="flex flex-col gap-2 child:m-0">
                    <h4 className="text-justify text-lg xl:text-xl"> {getTranslation(presentation, "name", lang)} </h4>
                    <p>
                      {
                        presentation.speakers
                          .reduce((acc, e) => `${acc}, ${e.name}`, "")
                          .substring(2)
                      }
                    </p>
                    <p> Lugar:  
                      {
                        " " + (presentation.onCampusLocation?.name || " ")
                      }
                    </p>
                  </div>
                </td>
                {/* <td>
                  <div className="flex flex-col lg:flex-row justify-center gap-2 
                    child:min-w-[100px] child:flex child:items-center child:justify-center">
                    {
                      (myPresentations.includes(presentation._id) || areAllMine) ?
                        (
                          <button onClick={() => { onSubscribe(presentation._id) }} className=" p-2 rounded-sm text-conia-dark text-sm font-semibold
                            bg-accent disabled:!bg-gray-400 disabled:!text-gray-600">
                            {t("app_unsubscribe_btn")}
                          </button>
                        ) :
                        (
                          <button onClick={() => { onSubscribe(presentation._id) }} className=" p-2 rounded-sm text-conia-dark text-sm font-semibold
                            bg-primary-disabled disabled:!bg-gray-400 disabled:!text-gray-600">
                            {t("app_subscribe_btn")}
                          </button>
                        )
                    }
                  </div>
                </td> */}
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default PresentationTable;