/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import Card from "../../../../components/Card/Card";
import BasePage from "../../../BasePage/BasePage";

import buho_fialinks from "../../../../assets/img/buho_link_black.svg";
import logo_fialinks from "../../../../assets/img/conialogo2023.svg";

import QRCode from 'react-qr-code';
import { IoTicket } from 'react-icons/io5';

import { useUserContext } from '../../../../contexts/UserContext';
import { useConfigContext } from '../../../../contexts/ConfigContext';
import { useTranslation } from 'react-i18next';

import axios from 'axios';


const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const LandingPage = ({ onSubscribe = () => {} }) => {
    const { user } = useUserContext();
    const { startLoading, stopLoading } = useConfigContext();
    const { t } = useTranslation();

    const [isSubscribed, setSubscribe] = useState(null);

    useEffect(()=> {
        verifyUserSubs();
    }, [user]);

    const verifyUserSubs = useCallback(() => {
        const verifySubscribed = async () => {
            if(user) {
                setSubscribe(await amISubs());
            }
        }
        verifySubscribed();
    }, []);

    const amISubs = async () => {
        let response = null;
        startLoading("am-i-subs-verify"); 
        try {
            const { data } 
                = await axios.post(`/event/am-i-subscribed`, { event: EVENT_ID });
            response = data.status;
        } catch (error) {
            console.error(error);
        } finally {
            stopLoading("am-i-subs-verify");
            return response;
        }
    }

    return (
        <BasePage  className="md:!p-4"
            center stretched>
            <Card className="min-h-[50vh] flex flex-col justify-center items-center gap-4
                !bg-none !shadow-none">
                
                {user &&
                    <>
                        <div className='p-4 bg-white'>
                            <QRCode value={user.email}/>
                        </div>
                        <h2 className='text-center text-zinc-100'> { user.email } </h2>
                    </>
                }

            </Card>

            <Card className="min-h-[50vh] relative flex flex-col justify-center gap-4 
                !bg-none !shadow-none">

                <h1 className="text-center text-xl text-zinc-100 text-4xl">
                    { isSubscribed ? t("app_already_subscribe_text") : t("app_subscribe_text") }
                </h1>

                <figure className="h-14 flex justify-center m-0 gap-2">
                    
                    <img className="full object-contain" src={logo_fialinks} alt="FIALinks"/>
                </figure>

                { (isSubscribed !== null) && 
                    <>
                    { 
                        !isSubscribed ? 
                        <button onClick={()=> { onSubscribe(false, verifyUserSubs) }} 
                            className="p-4 my-8 flex gap-2 justify-center items-center 
                            rounded-tr-xl rounded-bl-xl
                            hover:scale-110 text-xl
                            bg-primary md:self-center">
                            <IoTicket/> { t("app_subscribe_btn") }
                        </button> :
                        <button onClick={()=> { onSubscribe(true, verifyUserSubs) }}
                            className="p-4 my-8 flex gap-2 justify-center items-center 
                            rounded-tr-xl rounded-bl-xl
                            hover:scale-110 text-xl
                            bg-rose-800 text-slate-50 md:self-center">
                            <IoTicket/> { t("app_unsubscribe_btn") }
                        </button>
                    }
                    </>
                }
            </Card>
        </BasePage>
    );
}

export default LandingPage;