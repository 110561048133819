import { Navigate, Route, Routes } from "react-router-dom";
import axios from "axios";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { useSnackbar } from "react-simple-snackbar";

import {
  MdAssignment,
  MdHome,
  MdBookmarks,
  MdBookmark,
  MdInfo,
} from "react-icons/md";

import { useConfigContext } from "../../contexts/ConfigContext";
import { useInfoContext } from "../../contexts/InfoContext";
import { useTranslation } from "react-i18next";

import IndexPage from "./IndexPage/IndexPage";
import CareerPage from "./CareerPage/CareerPage";
import { getTranslation, mapToLanguages } from "../../services/translation";
import AboutPage from "../AboutPage/AboutPage";
import { useEffect, useState } from "react";

const EVENT_ID = process.env.REACT_APP_EVENT_ID;

const MainRouterPage = () => {
  const [openSnack] = useSnackbar();
  const { lang } = useConfigContext();
  const { careers } = useInfoContext();
  const [eventInfo, setEventInfo] = useState({});

  const [date, setDate] = useState([]);
  const { startLoading, stopLoading } = useConfigContext();

  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        startLoading("fetch-event-info");

        const { data } = await axios.get(`/info/event/${EVENT_ID}`);
        setEventInfo(mapToLanguages(data, "summary", "slogan"));
        const _date = data.dates.map((dateItem) =>
          mapToLanguages(dateItem, "title", "date", "description")
        );
        setDate(_date);
      } catch (error) {
        openSnack(translate("snack_error_fetch"));
      } finally {
        stopLoading("fetch-event-info");
      }
    };
    fetchInfo();
  }, []);

  const pages = [
    {
      id: "home-page",
      title: translate("header_home_text"),
      icon: <MdHome />,
      url: "/",
      loginReq: false,
      disabled: false,
      component: <IndexPage date={date} eventInfo={eventInfo} lang={lang} />,
    },
    {
      id: "my-schedule-page",
      title: translate("header_my_schedule_text"),
      icon: <MdAssignment />,
      url: "/my-schedule",
      loginReq: true,
      disabled: false,
      component: <Navigate to="/app/my-schedule" />,
    },
    {
      id: "careers-pages",
      title: translate("header_careers_text"),
      icon: <MdBookmarks />,
      pages: careers.map((career) => ({
        id: `${career.code}_page`,
        title: getTranslation(career, "name", lang),
        icon: <MdBookmark />,
        loginReq: false,
        disabled: false,
        component: <CareerPage />,
        url: `/career/${career.uri}`,
      })),
    },
    {
      id: "about-page",
      title: translate("header_about_text"),
      icon: <MdInfo />,
      url: "/about",
      loginReq: false,
      disabled: false,
      component: <AboutPage eventInfo={eventInfo} dates={date} lang={lang} />,
    },
  ];

  const flatRoutes = (pages = []) => {
    return pages.reduce((acc, page) => {
      return page.pages
        ? [...acc, ...flatRoutes(page.pages)]
        : [
            ...acc,
            <Route key={page.id} path={page.url} element={page.component} />,
          ];
    }, []);
  };

  return (
    <>
      <Header pages={pages} eventID={EVENT_ID} />
      <Routes>
        {flatRoutes(pages)}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer pages={pages} />
    </>
  );
};

export default MainRouterPage;
