import { useState } from "react";

import GoogleLogin from "react-google-login";
import { FormGroup, Input, Label } from "reactstrap";

import { Link } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";

import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from "react-i18next";

const SignInForm = ({ GCID = "", onSignIn = () => { } }) => {

    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");

    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const onGoogleSignIn = (res) => {
        const { error, tokenId } = res;

        if (error) {
            openSnack(t("snack_error_signin_google_fail"));
        } else {
            onSignIn(tokenId);
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (!identifier || !password) {
            openSnack(t("snack_warn_fill_fields"), 3000);
            return;
        }

        onSignIn(identifier, password);
    }

    return (
        <>
            <form onSubmit={onSubmitForm}
                className="min-w-0 flex flex-col justify-center p-2 child:my-2">

                <h2> { t("auth_si_title") } </h2>

                <FormGroup>
                    <Label for="login-identifier"> { `${t("auth_form_code")} / ${t("auth_form_email")}` } </Label>
                    <Input
                        id="login-identifier"
                        name="identifier"
                        placeholder={ t("auth_form_email_eg") }
                        value={identifier}
                        onChange={({ target }) => { setIdentifier(target.value) }}
                        type="email" />
                </FormGroup>

                <FormGroup>
                    <Label for="login-password"> { t("auth_form_pass") } </Label>
                    <Input
                        id="login-password"
                        name="password"
                        value={password}
                        onChange={({ target }) => { setPassword(target.value) }}
                        type="password" />
                </FormGroup>

                <button type="submit" className="p-2 bg-primary text-black rounded-md shadow-md hover:bg-amber-700">
                    { t("auth_form_si_btn") }
                </button>

                <GoogleLogin
                    clientId={GCID}
                    onSuccess={onGoogleSignIn}
                    onFailure={onGoogleSignIn}
                    cookiePolicy="single_host_origin"
                    icon={false}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}
                            className="flex gap-2 p-2 justify-center items-center rounded-md shadow-md
                                    border-1 border-secondary text-black hover:border-0 hover:!text-white hover:bg-secondary">

                            <FcGoogle /> { t("auth_form_gsi_btn") }

                        </button>
                    )}
                />
            </form>

            <div className="p-2 flex flex-col gap-2 m-0">
                <p className="text-lg text-gray-600 m-0">
                    { t("auth_info_register_text") } <Link to="/auth/signup" className="text-primary"> { t("auth_info_register_link") } </Link>
                </p>

                <p className="text-lg text-gray-600">
                    { t("auth_info_forgot_text") } <Link to="/auth/forgot-password" className="text-primary"> { t("auth_info_forgot_link") } </Link>
                </p>
            </div>
        </>
    );
}

export default SignInForm;