import { useInfoContext } from "../../../../contexts/InfoContext";
import { getTranslation } from "../../../../services/translation";

import { useConfigContext } from "../../../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Careers = () => {
  const { careers } = useInfoContext();
  const { lang } = useConfigContext();
  const { t } = useTranslation();

  const mappedCards = careers.map((career) => (
    <Link
      className="bg-cover bg-center cursor-pointer shrink-0 w-[300px] rounded-md shadow-sm
      bg-accent hover:scale-105"
      to={`/career/${career.uri}`}
      key={`card_${career.code}`}
    >
      <div className="h-full rounded-md flex flex-col gap-2 pb-4 min-h-[100px] overflow-hidden">
        <img src={career.image} alt={getTranslation(career, "name", lang)} className="w-[300px] min-h-[225px] object-cover" />
        <h3 className="text-center text-secondary font-bold text-lg">
          {getTranslation(career, "name", lang)}
        </h3>
      </div>
    </Link>
  ));

  return (
    <section className="flex flex-col items-center gap-4 text-3xl max-w-[1024px]">
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
        {t("index_conferences_text")}
      </h2>
      <div className="flex justify-center ali flex-wrap gap-4">{mappedCards}</div>
    </section>
  );
};

export default Careers;
