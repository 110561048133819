import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { useUserContext } from "../../contexts/UserContext";
import { useConfigContext } from "../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import API from "../../constants/API";

import {
  MdMenu,
  MdDashboard,
  MdEdit,
  MdExitToApp,
  MdTranslate,
  MdArrowDropDown,
  MdArrowDropUp,
} from "react-icons/md";
import EjesTematicos from "../EjesTematicos/EjesTematicos";

const Header = ({ pages = [], concatPath = "", eventID, isLogin = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesplegable, setIsDesplegable] = useState(false);

  const { token, logout } = useUserContext();
  const { startLoading, stopLoading, setLang, lang, scrollY } =
    useConfigContext();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const logo_conia = `${API.FILE_BASEURL}/logos/${eventID}.webp`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDesplegable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onLogout = (e) => {
    e.preventDefault();
    startLoading("logging-out");
    logout();
    navigate("/");
    stopLoading("logging-out");
  };

  const optionsGeneration = (page) => {
    return (
      <li
        key={`nav-${page.id}`}
        className="nav-item flex items-start gap-12 justify-start"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <NavLink
          to={concatPath + page.url}
          className={`text-on-nav-color flex flex-row items-center justify-start no-underline text-xl gap-2 ${HoverEffect}`}
        >
          <div className="text-accent-color-secondary text-2xl">
            {page.icon}
          </div>
          {page.title}
        </NavLink>
      </li>
    );
  };

  const linkGeneration = (pagesToMap) => {
    return pagesToMap.map((page) => {
      if (page.loginReq && !token) {
        return;
      }
      if (page.pages) {
        return (
          <EjesTematicos
            key={`nav-${page.id}`}
            page={page}
            buttonRef={buttonRef}
            isDesplegable={isDesplegable}
            menuRef={menuRef}
            setIsDesplegable={setIsDesplegable}
            optionsGeneration={optionsGeneration}
            hoverEffect={HoverEffect}
          />
        );
      } else {
        return optionsGeneration(page);
      }
    });
  };

  const HoverEffect = "hover:text-accent";

  return (
    <nav
      className="sticky top-0 left-0 drop-shadow-sm bg-nav-color z-50 flex items-center w-full font-sans"
      data-scroll={scrollY}
    >
      <h1 className="hidden">XII CONIA 2024</h1>
      {/* TODO(Leer desde la base) */}
      <div
        className={`flex ${
          isOpen ? "flex-col lg:flex-row" : "flex-row"
        } items-center w-full ml-4 mr-4`}
      >
        <div
          className={`flex justify-between text-xl w-full lg:w-max lg:justify-center`}
        >
          <Link to="/">
            <img className="h-10 m-3" src={logo_conia} alt={`${eventID}`} />
          </Link>

          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={`lg:hidden text-accent-color-secondary `}
          >
            <MdMenu className="text-on-nav-color" />
          </button>
        </div>

        <div
          className={`${
            isOpen ? "flex" : "hidden"
          } flex-col p-2 w-full lg:flex lg:flex-1 lg:flex-row ${
            isOpen ? "gap-2" : "gap-4"
          }`}
        >
          <ul
            className={`flex flex-col justify-start lg:flex-row lg:flex-1 items-start lg:items-center m-0 pl-0 ${
              isOpen ? "gap-2" : "gap-4"
            }`}
          >
            {linkGeneration(pages)}
          </ul>
          <ul
            className={`flex flex-col lg:flex-row gap-4 items-start lg:items-center m-0 pl-0`}
          >
            <li
              className="flex flex-col gap-1 text-xl"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <button
                onClick={() => setLang(lang === "es" ? "en" : "es")}
                className={`cursor-pointer  text-on-nav-color flex items-center gap-2 ${HoverEffect}`}
              >
                <MdTranslate className="text-accent-color-secondary" />
                {lang === "es" ? "English" : "Español"}
              </button>
            </li>
          </ul>
          {/* Funcion de login */}
          {/* {!isLogin && (
            <ul
              className={`flex flex-col lg:flex-row gap-2 lg:gap-4 items-start lg:items-center m-0 pl-0`}
            >
              <li
                className="flex flex-col gap-1"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <Link
                  className={`text-on-nav-color flex justify-center items-center gap-2 text-xl no-underline ${HoverEffect}`}
                  to={token ? "/app" : "/auth/signin"}
                >
                  {token ? (
                    <MdDashboard className="text-accent-color-secondary" />
                  ) : (
                    <MdEdit className="text-accent-color-secondary" />
                  )}
                  {token
                    ? translate("header_dashboard_text")
                    : translate("header_signin_text")}
                </Link>
              </li>
              {token && (
                <li
                  className="flex flex-col gap-1 lg:m-0 lg:p-0"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <button
                    onClick={onLogout}
                    className={`flex items-center cursor-pointer text-on-nav-color text-xl gap-2 ${HoverEffect}`}
                  >
                    <MdExitToApp className="text-accent-color-secondary" />
                    {translate("header_signout_text")}
                  </button>
                </li>
              )}
            </ul>
          )} */}
        </div>
      </div>
    </nav>
  );
};

export default Header;
