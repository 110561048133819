import { useState } from "react";

import GoogleLogin from "react-google-login";
import { FormGroup, Input, Label, FormText } from "reactstrap";

import { Link } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from 'react-i18next';

import { FcGoogle } from 'react-icons/fc';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

const SignUpForm = ({ GCID = "", onSignUp = () => { } }) => {
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");

    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const onGoogleSignIn = (res) => {
        const { error, tokenId } = res;

        if (error) {
            openSnack(t("snack_error_signin_google_fail"));
        } else {
            onSignUp(tokenId);
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (!identifier || !password) {
            openSnack(t("snack_warn_fill_fields"), 3000);
            return;
        }

        onSignUp(identifier, password);
    }

    return (
        <>
            <form onSubmit={onSubmitForm}
                className="min-w-0 flex flex-col justify-center p-2 child:my-2">

                <div className="flex items-center gap-2">
                    <Link className="cursor-pointer text-2xl text-black" to="/auth/signin"> <IoArrowBackCircleSharp /> </Link>
                    <h2 className="!mb-0 flex-1" > { t("auth_su_title") } </h2>
                </div>

                <FormGroup>
                    <Label for="login-identifier"> { t("auth_form_email") } </Label>
                    <Input
                        id="login-identifier"
                        name="identifier"
                        placeholder={ t("auth_form_email_eg") }
                        value={identifier}
                        onChange={({ target }) => { setIdentifier(target.value) }}
                        type="email" />
                </FormGroup>

                <FormGroup>
                    <Label for="login-password"> { t("auth_form_pass") } </Label>
                    <Input
                        id="login-password"
                        name="password"
                        value={password}
                        onChange={({ target }) => { setPassword(target.value) }}
                        type="password" />
                    <FormText> { t("auth_form_password_regex") } </FormText>
                </FormGroup>

                <button type="submit" className="p-2 bg-primary text-black rounded-md shadow-md hover:bg-amber-700">
                { t("auth_form_su_btn") }
                </button>

                <GoogleLogin
                    clientId={GCID}
                    onSuccess={onGoogleSignIn}
                    onFailure={onGoogleSignIn}
                    cookiePolicy="single_host_origin"
                    icon={false}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}
                            className="flex gap-2 p-2 justify-center items-center rounded-md shadow-md
                                    border-1 border-secondary text-black hover:border-0 hover:bg-secondary">

                            <FcGoogle /> { t("auth_form_gsu_btn") }

                        </button>
                    )}
                />
            </form>
        </>
    );
}

export default SignUpForm;