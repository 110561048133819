/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

import { useInfoContext } from "../../../contexts/InfoContext";
import { useConfigContext } from "../../../contexts/ConfigContext";
import { useUserContext } from "../../../contexts/UserContext";

import BasePage from "../../BasePage/BasePage";
import NewLine from "../../../components/NewLine/NewLine";
import PresentationTable from "./PresentationTable/PresentationTable";

import axios from "axios";
import { getTranslation, mapToLanguages } from "../../../services/translation";

const EVENT_ID = process.env.REACT_APP_EVENT_ID;

const CareerPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { careers } = useInfoContext();
  const { startLoading, stopLoading, lang } = useConfigContext();
  const { token } = useUserContext();
  const [openSnack] = useSnackbar();
  const navigate = useNavigate();

  const [careerCode, setCareerCode] = useState(undefined);
  const [career, setCareer] = useState(undefined);
  const [myPresentations, setMyPresentations] = useState([]);

  useEffect(() => {
    const tempPaths = pathname.split("/");
    const _career = careers.find(
      (_c) => _c.uri === tempPaths[tempPaths.length - 1]
    );

    setCareerCode(_career?.code);
  }, [pathname, careers]);

  useEffect(() => {
    const fetchCareerInfo = async () => {
      try {
        startLoading("fetch_specific_career_info");

        const { data } = await axios.get(
          `/info/theme/${EVENT_ID}/${careerCode}`
        );

        let _preCareer = mapToLanguages(data, "name", "description");
        _preCareer.main.sort((p1, p2) =>
          p1.datetime.localeCompare(p2.datetime)
        );
        _preCareer.related.sort((p1, p2) =>
          p1.datetime.localeCompare(p2.datetime)
        );

        const _main = _preCareer.main.map((pres) =>
          mapToLanguages(pres, "name")
        );
        const _related = _preCareer.related.map((pres) =>
          mapToLanguages(pres, "name")
        );

        _preCareer = { ..._preCareer, main: _main, related: _related };

        if (token) {
          const { data: rawMine } = await axios.get(
            `/user/presentation/${EVENT_ID}`
          );
          const { data: mineList } = rawMine.virtualList;
          setMyPresentations(mineList.map((p) => p._id));
        }

        setCareer(_preCareer);
      } catch (error) {
        openSnack(t("snack_error_fetch"));
      } finally {
        stopLoading("fetch_specific_career_info");
      }
    };

    if (careerCode) {
      fetchCareerInfo();
    }
  }, [careerCode, token]);

  const onSubscribe = async (presentationId) => {
    if (!token) {
      return navigate("/auth/signin", {
        state: career ? `/career/${career.uri}` : "/",
      });
    }

    startLoading("subs-to-pres");
    const alreadySub = myPresentations.includes(presentationId);

    try {
      if (!alreadySub) {
        await axios.post(`/user/presentation/${presentationId}`, {
          virtual: true,
        });
        setMyPresentations([...myPresentations, presentationId]);
      } else {
        await axios.delete(`/user/presentation/${presentationId}`, {
          data: { virtual: true },
        });
        setMyPresentations(myPresentations.filter((p) => p !== presentationId));
      }
    } catch (error) {
      openSnack(t("snack_error_unexpected"));
    } finally {
      stopLoading("subs-to-pres");
    }
  };

  return (
    <>
      {/* {career && (
        <figure className="w-full h-[25vh] min-h-[150px] self-center overflow-hidde m-0">
          <img
            className="w-full h-full object-cover"
            src={career.image}
            alt={`${getTranslation(career, "name", lang)}`}
          />
        </figure>
      )} */}
      <BasePage
        className="md:flex-col gap-4
      py-4 px-[1rem] lg:!px-[12rem] xl:!px-80 sm:!px-16 text-black flex-1"
      >
        {career && (
          <>
            <h2 className="py-2 border-b-[1px] border-solid border-conia-dark text-2xl font-bold">
              {getTranslation(career, "name", lang)}
            </h2>

            <NewLine className="text-justify text-xl">
              {getTranslation(career, "description", lang)}
            </NewLine>

            {career.main && career.main.length > 0 && (
              <>
                <h3 className="text-justify mt-3">
                  {`${t("career_presentation_quote")} ${getTranslation(
                    career,
                    "name",
                    lang
                  )}`}
                </h3>

                <PresentationTable
                  presentations={career.main}
                  myPresentations={myPresentations}
                  onSubscribe={onSubscribe}
                />
              </>
            )}

            {career.related && career.related.length > 0 && (
              <>
                <h3 className="text-justify mt-3">
                  {`${t("career_related_quote")}`}
                </h3>

                <PresentationTable
                  presentations={career.related}
                  myPresentations={myPresentations}
                  onSubscribe={onSubscribe}
                />
              </>
            )}
          </>
        )}
      </BasePage>
    </>
  );
};

export default CareerPage;
