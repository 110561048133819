import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiSocialLinkedin, TiSocialLinkedinCircular } from "react-icons/ti";

import axios from "axios";
import Slider from "../../../../components/Slider/Slider";
import {
  getTranslation,
  mapToLanguages,
} from "../../../../services/translation";
const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";
const MASTER_TALK_CODE = "magis";

const Speakers = ({ lang = "es" }) => {
  const { t: translate } = useTranslation();
  const [slidesToShow, setSlidesToShow] = useState(1);

  const [masterTalks, setMasterTalks] = useState([]);

  useEffect(() => {
    const fetchMasterTalk = async () => {
      try {
        // Buscamos el ID del theme que nos interesa
        const { data: themes } = await axios.get(
          `info/event/themes/${EVENT_ID}`
        );
        const magisTheme = themes.data.find(
          (theme) => theme.code == MASTER_TALK_CODE
        );
        // Fitrar las presentaciones del theme
        const { data: presentations } = await axios.get(
          `/presentation/${EVENT_ID}`
        );
        let masterPresentations = presentations.data.filter((presentation) =>
          presentation.themes.includes(magisTheme._id)
        );
        masterPresentations = masterPresentations.map((presentation) =>
          mapToLanguages(presentation, "name", "summary")
        );
        setMasterTalks(masterPresentations);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMasterTalk();
  }, []);

  const updateSlidesToShow = () => {
    const width = window.innerWidth;

    if (width >= 1340) {
      setSlidesToShow(2); // Pantallas grandes
    } else {
      setSlidesToShow(1); // Pantallas pequeñas
    }
  };

  useEffect(() => {
    // Establecemos slidesToShow según el tamaño de la ventana inicial
    updateSlidesToShow();

    // Agregamos un listener para cambiar slidesToShow cuando cambia el tamaño de la ventana
    window.addEventListener("resize", updateSlidesToShow);

    // Limpiamos el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  return masterTalks.length > 0 ? (
    <section className="flex-col items-center gap-4 w-[90%]">
      <h2
        className="p-2 border-black text-3xl"
        style={{ borderBottom: 1, borderStyle: "solid" }}
      >
        {translate("index_ponentes_text")}
      </h2>
      <Slider slidesToShow={slidesToShow} className="max-w-[85%] mt-5">
        {masterTalks.map((talk, index) => (
          <div
            key={talk._id}
            data-index={index}
            className="h-[725px] xs:h-[625px] md:h-[350px] lg:h-[300px] shadow sm:flex bg-primary-800 border-primary-800 xl:h-[350px] rounded-lg"
          >
            <a href={/*`/career/${Speakers.uri}`*/ "#"} className="md:w-[300px] md:min-w-[300px]">
              <img
                className="w-full h-[300px] md:h-full object-cover rounded-lg sm:rounded-none sm:rounded-l-lg"
                src={talk.speakers[0].imageUrl}
                alt={talk.speakers[0].name}
              />
            </a>
            <div className="p-5">
              <h3 className="text-xl font-bold tracking-tight text-white">
                <a href="#">{talk.speakers[0].name}</a>
              </h3>
              <h4 className="text-lg text-gray-300">
                {getTranslation(talk, "name", lang)}
              </h4>
              {Object.keys(talk.summary).length > 0 && (
                <h5 className="text-lg mt-3 mb-4 font-light text-gray-300">
                  {getTranslation(talk, "summary", lang)}
                </h5>
              )}
              {/* <ul className="flex space-x-4 sm:mt-0">
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    <TiSocialLinkedin className="w-5 h-5" />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        ))}
      </Slider>
    </section>
  ) : null;
};

export default Speakers;
