/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup, Input, Label, FormText } from 'reactstrap';

import { IoAddCircle, IoCloseCircle } from "react-icons/io5";
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const InputList = ({values=[], onAdd=()=>{}, onRemove=()=>{}, className="", label="", id="", name="", ...rest}) => {
    const [value, setValue] = useState("");
    const { t } = useTranslation();

    const onAddItem = (e) => {
        e.preventDefault();
        if(value) {
            onAdd(value);
            setValue("");
        }
    }

    const mapValues = useMemo(() => (values.map((val) => (
        <div key={`${val.id}`}
            onClick={()=> onRemove(val)}
            className="flex-1 p-2 flex items-center justify-between gap-2 cursor-pointer
            bg-white text-white rounded-xl whitespace-nowrap select-none">
        
            { val.value } 
            <IoCloseCircle/>  
            
        </div>
    ))), [values]);
    
    return (
        <FormGroup className={`${className}`} {...rest}>
            <Label for={id}>
                { label }
            </Label>
            <div className='flex gap-2'>
                <Input className='flex-1'
                    id={id}
                    name={name} 
                    value={value}
                    onKeyUp={(e)=> { (e.key === "Enter") && onAddItem(e) }}
                    onBlur= {(e)=> { onAddItem(e) }}
                    onChange={({ target }) => setValue(target.value)}/>
                
                <button onClick={onAddItem}>
                    <IoAddCircle className='text-primary text-xl'/>
                </button>
            </div>
            <FormText>
                {t("profile_submit_partial_text")} {label.toLowerCase()} {t("profile_enter_partial_text")}
            </FormText>
            <div className={`pr-6 flex flex-wrap justify-center gap-2 ${values.length > 0 && "pt-2"}`}>
                { mapValues }
            </div>
        </FormGroup>
    );
}

export default InputList;