/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";

import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import { useUserContext } from "../../../../contexts/UserContext";
import { useTranslation } from "react-i18next";

import BasePage from "../../../BasePage/BasePage";
import PresentationTable from "../../../MainRouterPage/CareerPage/PresentationTable/PresentationTable";
import { mapToLanguages } from "../../../../services/translation";

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const MyPresentationPage = () => {

    const { token } = useUserContext();
    const { startLoading, stopLoading } = useConfigContext();
    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const [ myPresentations, setMyPresentations ] = useState([]);

    useEffect(() => {
        const fetchPresentations = async () => {
            startLoading("fetch-my-presentations");
            try {
                if (token) {
                    const { data } = await axios.get(`/user/presentation/${EVENT_ID}`);

                    data.virtualList.data.sort((p1, p2) => p1.datetime.localeCompare(p2.datetime));
                    setMyPresentations(data.virtualList.data.map(p => mapToLanguages(p, "name")));
                }
            } catch (error) {
                openSnack(t("snack_error_fetch"));
                setMyPresentations([]);
            } finally {
                stopLoading("fetch-my-presentations");
            }
        }

        fetchPresentations();
    }, [token]);

    const onSubscribe = async (presentationId) => {
        startLoading("subs-to-pres");
        
        try {
            await axios.delete(`/user/presentation/${presentationId}`, { data: { virtual: true } });
            setMyPresentations(myPresentations.filter(p => p._id !== presentationId));

        } catch (error) {
          openSnack(t("snack_error_unexpected"));
        } finally {
          stopLoading("subs-to-pres");
        }
      }

    return (
        <BasePage className="md:flex-col md:items-center text-zinc-100">
            <div className="p-4 w-full max-w-7xl">
                <h2 className="capitalize flex gap-3 items-center text-3xl ">
                    { t("header_my_schedule_text") }
                </h2>

                <div className="bg-primary w-full h-[2px] my-4" />
                
                {
                    myPresentations.length === 0 &&
                    <h4 className="my-5 text-center">
                        { t("my_schedule_no_set_title") }
                    </h4>
                }

                {
                    myPresentations.length !== 0 &&
                    <PresentationTable presentations={myPresentations} onSubscribe={onSubscribe} areAllMine/>
                }
            </div>
        </BasePage>
    );
}

export default MyPresentationPage;